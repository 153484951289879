import {
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import instruments from '@/data/instruments.json'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import useInstruments from '../useInstruments'
import processAgents from './processAgents'

// strategy state
const data = ref({
  pageSize: 1200,
  agents: JSON.parse(JSON.stringify(instruments.array)),
})

export default function useAgents() {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { instrumentActive } = useInstruments()
  const instrumentType = computed(() => instrumentActive.value.name)
  const { http } = useApollo()

  async function getAgents() {
    const { pageSize } = data.value
    let offset = 0
    let raw
    if (data.value.agents[instrumentType.value].length > 0) {
      offset = data.value.agents[instrumentType.value].length
    }
    // need to add instrument filter when able
    await http.value.query({
      query: gql`
        query getHermesAgents {
          mlfx_agents(
            offset: ${offset},
            limit: ${pageSize},
            order_by: {metric: {avg_return: desc}},
            where: {retired: {_eq: false},
             _and: {strategy: {instrument: {name: {_eq: "${instrumentType.value}"}}}}})
            {
            creation_begin_time
            creation_end_time
            id
            lookahead
            lookbehind
            rules_count
            retired
            strategy {
              instrument {
                name
                id
              }
              method {
                id
                name
              }
              timeframe {
                name
              }
            }
            metric {
              avg_activation
              avg_max_neg
              avg_max_pos
              avg_return
              avg_revenue
              avg_sl
              avg_tp
              begin_time
              dataset_size
              end_time
              id
              max_sl
              max_tp
              min_sl
              min_tp
              stdev_activation
              stdev_max_neg
              stdev_max_pos
              stdev_revenue
              stdev_sl
              stdev_tp
              total_return
              total_revenue
              trades_lost
              trades_won
            }
          }
      }
      `,
    })
      .then(async result => {
        if (result.data[`${frontSchema}_agents`].length > 0) {
          raw = result.data[`${frontSchema}_agents`]
          const processed = await processAgents(raw)
          data.value.agents[instrumentType.value].push(...processed)
        } else {
          // no more agents in database
          // provide feedback
        }
      })
      .catch(err => console.error('getAgents', err))
    return raw
  }

  watch(() => instrumentType.value, () => {
    getAgents()
  })

  return {
    agents: computed(() => data.value.agents[instrumentType.value]),
    getAgents,
  }
}
