<template>
  <div class="no-pad-wrap">
    <tabs>
      <template v-slot:instruments>
        <InstrumentDropdown class="border-right" />
      </template>
      <template v-slot:before>
        <input
          v-model="gridFilter"
          placeholder="Filter grid"
          class="grid-filter"
          type="text"
        >
      </template>
      <tab :index="0">
        <GridSetup
          :processed-data="strategies"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <tab :index="1">
        <GridSetup
          :processed-data="strategies"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <tab :index="2">
        <GridSetup
          :processed-data="strategies"
          :search-filter="gridFilter"
          :pagination="true"
        />
      </tab>
      <template v-slot:after>
        <div class="c-center">
          <span class="hermes-warning">page used for bug fixing</span>
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import InstrumentDropdown from '@/views/components/InstrumentDropdown.vue'
import useAgents from '@/composables/strategies/useAgents'
import useStrategies from '@/composables/strategies/useStrategies'
import useInstruments from '@/composables/useInstruments'
import GridSetup from '../../components/agGrid/GridSetup.vue'
import tab from '../../components/appTab.vue'
import tabs from '../../components/appTabs.vue'

export default {
  name: 'Strategies',
  components: {
    GridSetup,
    InstrumentDropdown,
    tab,
    tabs,
  },
  directives: { Ripple },
  data() {
    return {
      // Grid options
      gridFilter: '',
    }
  },
  setup() {
    const { setInstrumentActive, instrumentActive, instrumentList } = useInstruments()
    const { agents: strategies, getAgents } = useAgents()
    const {
      strategyActive,
      strategyList,
      setStrategyActive,
    } = useStrategies({ instrumentActive })

    // if (strategies.value[strategyType.value][instrumentType.value].length < 1) {
    //   getStrategies()
    // }

    getAgents()

    return {
      strategyActive,
      strategyList,
      setStrategyActive,
      strategies,
      // Instruments
      instrumentActive,
      instrumentList,
      setInstrumentActive,
    }
  },
  computed: {
    chart() {
      return this.$isDark.value ? {
        background: 'rgba(71, 183,132,.5)',
        text: 'white',
        grid: 'white',
        dataset1: 'blue',
        dataset2: 'green',
        dataset3: 'orange',
      } : {
        background: 'red',
        text: 'white',
        grid: 'white',
        dataset1: 'blue',
        dataset2: 'green',
        dataset3: 'orange',
      }
    },
  },
  methods: {
    exportCsv() {
      const event = new CustomEvent('exportCsv')
      window.dispatchEvent(event)
    },
  },
}
</script>

<style lang="scss" >
@use "../../components/agGrid/components/optionsBar";

.strategies-wrap {
  position: relative;
  background-color: rgb(243, 243, 243);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 4.7rem;
  height: 100vh;
  border-left: 5px solid rgb(243, 243, 243);
  border-right: 5px solid rgb(243, 243, 243);
  .full-tabs {
    height: 100%;
    .tab-content {
      height: 100%;
    }
    #strategies-tab {
      height: 100%;
    }
  }
}

.analysis-hermes-warning {
  background-color: rgb(251, 246, 177);
  font-size: 12px;
  height: 100%;
  padding: 0px 16px 0px 16px;
  border-radius: 0.4rem;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

label {
  font-weight: normal !important;
}

.toolbar button {
  margin-left: 5px;
  margin-right: 5px;
  padding: 2px;
}

.no-nav {
  display: none;
}

.top-wrap {
  padding: 0.8rem;
}

.c-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dark-layout {
  .strategies-wrap {
    border: 5px solid #1b1b1b;
    background-color: #1b1b1b;
  }
  .analysis-hermes-warning {
    background-color: rgb(129, 120, 0);
  }
}
</style>
