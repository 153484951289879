import toPIPs from '@/mixins/toPIPs'

async function processAgents(rawAgents) {
  // let buyAgent = 0
  // let sellAgent = 0
  const data = rawAgents.map(x => {
    const totalTrades = +x.metric.trades_lost + +x.metric.trades_won
    const accuracy = Math.round((x.metric.trades_won / totalTrades) * 1000) / 10
    const parsedRet = parseFloat(x.metric.avg_return)
    // const RR = `1 / ${Math.abs(x.metric.testAvgTp / x.metric.testAvgSl).toFixed(2)}`
    // const daysDataset = x.metric.dataset_size / 96 // 24*4 = 96 for 15M timeframe
    const weeksDataset = x.metric.dataset_size / (96 * 5)
    const frequency = Math.round(totalTrades / weeksDataset)
    const instrument = x.strategy.instrument.name
    let agentDirection
    // if (x.metric.avg_tp > 0) {
    //   buyAgent += 1
    //   agentDirection = 'BULLISH'
    // } else if (x.metric.avg_tp < 0) {
    //   sellAgent += 1
    //   agentDirection = 'BEARISH'
    // }
    return {
      avgMaxPos: toPIPs(instrument, Math.abs(x.metric.avg_max_pos)),
      avgMaxNeg: toPIPs(instrument, Math.abs(x.metric.avg_max_neg)),
      agentDirection,
      accuracy,
      avgReturn: parsedRet,
      avgActivation: parseFloat(x.metric.avg_activation) * 100,
      // don't use toFixed or formatting here, value needs to be number to sort
      id: x.metric.id,
      dataset: x.metric.dataset_size,
      // reward: Math.abs(x.metric.avg_tp) / Math.abs(x.metric.avg_tp),
      pipsTP: toPIPs(instrument, Math.abs(x.metric.avg_tp)),
      pipsSL: toPIPs(instrument, Math.abs(x.metric.avg_sl)),
      maxTP: toPIPs(instrument, Math.abs(x.metric.max_tp)),
      maxSL: toPIPs(instrument, Math.abs(x.metric.max_sl)),
      minTP: toPIPs(instrument, Math.abs(x.metric.min_tp)),
      minSL: toPIPs(instrument, Math.abs(x.metric.min_sl)),
      indicators: null,
      totalReturn: parseFloat(x.metric.total_return),
      stdevActivation: parseFloat(x.metric.stdev_activation) * 100,
      totalRules: x.metric.rules_count,
      totalTrades,
      tradesLost: Number(x.metric.trades_lost),
      tradesWon: Number(x.metric.trades_won),
      frequency: Number(frequency),
      // avgRR: RR,
      instrument,
      strategyType: 'hermes',
      timeframe: x.strategy.timeframe.name,
      lookahead: x.lookahead,
      lookbehind: x.lookbehind,
      rules: x.rules_count,
      creationBegin: x.creation_begin_time,
      trainingEnd: x.creation_begin_time,
    }
  })
  return data
}

export default processAgents
